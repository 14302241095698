.header-image{
    visibility: hidden;
  }

.title {
    font-family: "PT Serif";
    width: 100%;
    font-size: max(24px,3vw);
    color: #ffbd9d;
    margin-left: 4vw;
    padding:0;
    align-items: center;
}

GalleryElement{
    width:100%;
}