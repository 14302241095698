.header-image{
    visibility: visible;
}

body{
    background-color: #1B232A;
}

.intro{
    color: #ffbd9d;
    font-family: "PT Serif";
    font-size: max(36px, 3vw);
    flex-direction: column;
    display: flex;
    margin-top: 20vh;
    justify-content: center;
    align-items: center;
    animation-name: sunrise;
    animation-duration: 8s;
    animation-timing-function: ease-in;
    line-height: 2px;
}


.subhead {
    display: flex;
    color: ivory;
    font-family: "PT Serif";
    font-size: max(32px,2.5vw);
    align-self: baseline;
}

@keyframes sunrise {
    from {color: ivory;}
    to {color: #ffbd9d;}
  }

.header-image{
    position: absolute;
    z-index: -1;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: 50% 25%;
    height: auto;
}