.body{
  color: ivory;
}

h1 {
  font-family: "PT-Serif";
  font-weight: 800;
}

h2{
  font-family: "PT-Serif";
  font-weight: 700;
}

.header-image{
  visibility: hidden;
}