.name {
    color: #011826;
    font-family: "PT Serif";
    
    width: 30vw;
    height: 30;
    left: 0px;
    right: auto;
    /* position: top; */
    display: inline-flex;
    margin: 2vw;
    font-size: 30px;
    margin-left: 1%;
}

a {
    color: ivory;
    font-weight: regular;
    text-decoration: none;

    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding: flex;
    vertical-align: flex;
    
}

.links {
    font-family: "Pt Serif";
    font-weight: 400;
    width: 40vw;
    max-width:40vw;
    min-width: 10vw;
    height: 30;
    left: auto;
    right: 0px;
    top: 10px;
    /* position: top; */
    display: flex;
    margin: 2vw;
    color: ivory;
    font-size: 16px;
    float: right;
    font-size: 20px;
}
.header-image{
    position: absolute;
    z-index: -1;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: 50% 25%;
    height: auto;
}